import React from 'react'
import { Helmet } from 'react-helmet'
import { technicalSupportAPI } from '../../../api/techniaclSupport'
import { TechnicalSupportForm } from './TechnicalSupportForm'
import { TechnicalSupportSuccess } from './TechnicalSupportSuccess'

export const TechnicalSupport = () => {
    const [sendMessage, { isSuccess, isLoading, error }] = technicalSupportAPI.useSendMessageMutation();

    // Логируем изменения состояния отправки
    console.log("Is loading:", isLoading);
    console.log("Is success:", isSuccess);
    console.log("Error:", error);

    return (
        <>
            <Helmet>
                <title>Тех. поддержка</title>
            </Helmet>
            {!isSuccess ? (
                <>
                    {isLoading && <p>Отправка сообщения...</p>}
                    <TechnicalSupportForm
                        onSubmit={(values) => {
                            console.log("Отправляем данные на сервер:", values);  // Лог данных перед отправкой
                            sendMessage(values)
                                .unwrap()
                                .then(() => {
                                    console.log("Сообщение успешно отправлено!");
                                })
                                .catch((err) => {
                                    console.error("Ошибка при отправке сообщения:", err);
                                });
                        }}
                    />
                </>
            ) : (
                <TechnicalSupportSuccess />
            )}
        </>
    );
};

